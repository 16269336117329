.radioBtn-container {
  display: flex;
  flex-direction: column;
}

input[type="radio"] {
  display: none;
}

.radioBtn-container label {
  display: block;
  cursor: pointer;
  font-weight: 500;
  margin-bottom: 10px;
}

.radioBtn-container label span {
  display: inline-flex;
  align-items: center;
  padding: 10px 20px 10px 10px;
  border-radius: 31px;
  transition: 0.25s ease;
}

.radioBtn-container label span:hover,
input[type="radio"]:checked + span {
  background-color: #d6d6e5;
}

.radioBtn-container label span:before {
  content: "";
  background-color: #fff;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-right: 10px;
  transition: 0.25s ease;
  box-shadow: inset 0 0 0 2px #00005c;
}

input[type="radio"]:checked + span:before {
  box-shadow: inset 0 0 0 5px #00005c;
}

.error {
  color: #cc0033;
  display: inline-block;
  font-size: 12px;
  line-height: 15px;
  margin: 5px 0 0;
}

.spinner-container {
  width: 400px;
  height: 400px;
}

.spinner-border {
  height: 50px;
  width: 50px;
  left: 220px;
  top: 140px;
  position: absolute;
}

.confirmation-heading {
  font-size: 20px;
}

.form-button {
  border: none;
  background: none;
  padding: 0px;
  color: #069;
  text-decoration: underline;
}

@media (max-width: 525px) {
  .spinner-container {
    width: 342px;
  }
  .spinner-border {
    left: 160px;
    top: 420px;
  }
  .class-radio {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  label {
    width: calc(100% / 2);
  }
  .email {
    display: "flex";
    align-items: "center";
  }
}
