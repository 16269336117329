.navbar {
  position: relative;
  -webkit-transition: all 0.6s ease-out;
  -moz-transition: all 0.6s ease-out;
  -o-transition: all 0.6s ease-out;
  -ms-transition: all 0.6s ease-out;
  transition: all 0.6s ease-out;
}

.navbar.sticky {
  position: fixed;
  box-shadow: 0px 15px 10px -15px #111;
}

/* @media (max-width: 525px) {
  .navbar {
    width: 400px !important;
  }
} */
