.card-title {
  font-size: large;
  text-align: center;
}

.card-img-top {
  width: 100%;
  height: 15vw;
  object-fit: contain;
}

.faq-section {
  background-color: cornflowerblue;
}

h1.faq {
  color: white;
  font-family: "Fredoka", cursive;
}

.accordion-button:focus {
  box-shadow: none;
}

.faqImage {
  width: 400px;
  margin-left: 50px;
}

.ageInfo {
  margin: 0px;
  overflow: hidden;
  padding-left: 0px;
}

.ageInfo li {
  list-style: none;
  display: inline-block;
  float: left;
  padding: 10px;
  font-size: 15px;
  font-weight: 700;
  text-align: center;
  width: 50%;
  background-color: #232220;
  color: #fff;
  border-top: 1px solid #fafafa;
  border-right: 1px solid #fafafa;
}

.ageInfo li:last-child {
  background-color: #fc7f0c;
  border-right: none;
}

.gallery-column {
  float: left;
  width: 33.33%;
  padding: 5px;
}
.gallery-row::after {
  content: "";
  clear: both;
  display: table;
}

.gallery-images {
  width: 100%;
  height: 200px;
  object-fit: cover;
  object-position: center;
  border: 5px solid #555;
}

@media (max-width: 525px) {
  .faqImage {
    width: inherit;
    margin-left: 0px;
  }

  .card-img-top {
    height: 50vw;
  }
}
